import React from "react";

export interface TailwindProgressBarProps {
    PercentProgressedValue: number;
}

export default class TailwindProgressBar extends React.Component<TailwindProgressBarProps> {
    render() {
        return (
            <div className="rounded overflow-hidden mt-1 w-full border border-[#a7a7a7] dark:border-[#4e4e4e] bg-[#a7a7a7] dark:bg-[#4e4e4e]">
                <div className="h-1 rounded bg-[#3070cf] dark:bg-[#4ec6ea]" style={{ width: this.props.PercentProgressedValue + '%' }}></div>
            </div>
        );
    }
}