import React from "react";
import { MyContactMe } from "../data/MyInfo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'glamor';

export interface TailwindMyContactMeProps {
    MyContactMe: MyContactMe;
}

export default class TailwindMyContactMe extends React.Component<TailwindMyContactMeProps> {
    render() {
        let hoverStyle = css({
            ":hover": {
                borderColor: this.props.MyContactMe.DesignColor,
                color: this.props.MyContactMe.DesignColor,
            }
        });

        return (
            <a href={this.props.MyContactMe.Link} title={this.props.MyContactMe.LinkTooltip} target="_blank" rel="noreferrer"
                className={`shadow transition ease-in-out hover:scale-105 border-2 px-4 py-3 rounded-full overflow-hidden border-[#f3f3f3] bg-[#f3f3f3] hover:bg-[#ececec] text-[#000] dark:bg-[#202224] dark:hover:bg-[#2c2f31] dark:text-[#dbdbdb] dark:border-[#202224] ${hoverStyle}`}>

                <FontAwesomeIcon icon={this.props.MyContactMe.Icon} className="fa-fw me-2" />
                <span dir="ltr" className="m-0">{this.props.MyContactMe.Name}</span>
                <span dir="ltr" className="ml-1">:</span>
                <span dir={this.props.MyContactMe.ValueRTL ? "rtl" : "ltr"} className="m-0">{this.props.MyContactMe.Value}</span>
            </a>
        );
    }
}
