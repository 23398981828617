import React from 'react';
import MyProfilePicture from './images/profile-picture.jpg';
import MyInfo from './data/MyInfo';
import TailwindProgressBar from './components/TailwindProgressBar';
import TailwindMyContactMe from './components/TailwindMyContactMe';
import './App.css';

function App() {
    return (
        <div className="container mx-auto sm:px-4">
            <div className="grid gap-5 grid-cols-1 md:grid-cols-2 justify-start">
                
                {/********************* درباره من *********************/}
                <div className="card">
                    <div className="h-[6.8rem] flex gap-4">
                        <img src={MyProfilePicture} alt="" className="w-auto h-full rounded-full" />

                        <div className="flex-auto flex items-center">
                            <div className="w-full h-min">
                                <h6 className="card-title">
                                    مهدی جمال
                                </h6>

                                <hr className="card-seprator" />

                                <div className="desc-title-container">
                                    <span>برنامه نویس فول استک</span>
                                    <br />
                                    <span><span className="font-bold">#</span> فریلنسری , دورکاری</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h6 className="card-title">
                            درباره من
                        </h6>

                        <div className="px-2">
                            {MyInfo.MyDetail}
                        </div>
                    </div>
                </div>

                {/********************* مهارت های من *********************/}
                <div className="card">
                    <h6 className="card-title">
                        مهارت های من
                    </h6>

                    <hr className="card-seprator" />

                    <div className="px-2 grid gap-5 grid-cols-1 md:grid-cols-2">
                        {MyInfo.MySkills.map((skill) => (
                            <div>
                                <div className="flex mx-1">
                                    <p className="flex-auto" dir="rtl">{skill.PercentPersian}%</p>
                                    <p className="w-auto" dir="ltr">{skill.Name}</p>
                                </div>

                                <TailwindProgressBar PercentProgressedValue={skill.PercentEnglish} />
                            </div>
                        ))}
                    </div>
                </div>

                {/********************* مدارک من *********************/}
                <div className="card">
                    <h6 className="card-title">
                        مدارک من
                    </h6>

                    <hr className="card-seprator" />

                    <div className="mx-2 grid items-center gap-5 grid-cols-2 lg:grid-cols-3">
                        {MyInfo.MyCertificates.map((certificate) => (
                            <a href={certificate.Image} className="shadow transition ease-in-out hover:scale-105 bg-[#ffffff] text-[#030303] dark:bg-[#1f1f1f] dark:text-[#e2e2e2] border-2 border-[#eeeeee] dark:border-[#1f1f1f] hover:border-[#3070cf] dark:hover:border-[#4ec6ea] overflow-hidden rounded-xl cursor-pointer">
                                <img src={certificate.Image} alt={certificate.ImageAlt} className="m-0 w-full" />
                                <div className="py-4 px-1 w-full text-center" dir="ltr">
                                    {certificate.Name}
                                </div>
                            </a>
                        ))}
                    </div>
                </div>

                {/********************* راه های ارتباطی *********************/}
                <div className="card">
                    <h6 className="card-title">
                        راه های ارتباطی
                    </h6>

                    <hr className="card-seprator" />

                    <div className="mx-2 grid items-center gap-3 grid-cols-1 xl:grid-cols-2">
                        {MyInfo.MyContactMe.map((contactMe) => <TailwindMyContactMe MyContactMe={contactMe} />)}
                    </div>
                </div>

                {/********************* نمونه کار ها *********************/}
                {/* <div className="card">
                    <h6 className="card-title">
                        نمونه کار ها
                    </h6>

                    <hr className="card-seprator" />

                </div> */}

                {/********************* خدمات من *********************/}
                {/* <div className="card">
                    <h6 className="card-title">
                        خدمات من
                    </h6>

                    <hr className="card-seprator" />

                </div> */}

                {/********************* مقالات من *********************/}
                {/* <div className="card">
                    <h6 className="card-title">
                        مقالات من
                    </h6>

                    <hr className="card-seprator" />

                </div> */}

            </div>
        </div>
    );
}

export default App;
