import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons'

export class MySkill {
    readonly Name: string;
    readonly PercentEnglish: number;
    readonly PercentPersian: string;

    constructor(name: string, percentEnglish: number) {
        this.Name = name;
        this.PercentEnglish = percentEnglish;
        this.PercentPersian = MySkill.EnglishNumbersToPersianNumbers(this.PercentEnglish);
    }
    static Create(name: string, percentEnglish: number): MySkill {
        return new MySkill(name, percentEnglish);
    }
    static CreateArray(skills: { name: string; percentEnglish: number }[]): MySkill[] {
        return skills.map(skill => new MySkill(skill.name, skill.percentEnglish));
    }

    private static readonly PersianDigits: string[] = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    private static EnglishNumbersToPersianNumbers(englishNumbers: number): string {
        return englishNumbers.toString().split('').map(x => MySkill.PersianDigits[parseInt(x)]).join('');
    }
}

export class MyCertificate {
    readonly Name: string;
    readonly ImageAlt: string;
    readonly Image: string;

    constructor(name: string, imageAlt: string, image: string) {
        this.Name = name;
        this.ImageAlt = imageAlt;
        this.Image = image;
    }
    static Create(name: string, imageAlt: string, image: string): MyCertificate {
        return new MyCertificate(name, imageAlt, image);
    }
    static CreateArray(skills: { name: string; imageAlt: string, image: string }[]): MyCertificate[] {
        return skills.map(skill => new MyCertificate(skill.name, skill.imageAlt, skill.image));
    }
}

export class MyContactMe {
    readonly Name: string;
    readonly Value: string;
    readonly ValueRTL: boolean;
    readonly LinkTooltip: string;
    readonly Link: string;
    readonly Icon: IconDefinition;
    readonly DesignColor: string;

    constructor(name: string, value: string, valueRTL: boolean, link: string, linkTooltip: string, icon: IconDefinition, designColor: string) {
        this.Name = name;
        this.Value = value;
        this.ValueRTL = valueRTL;
        this.LinkTooltip = linkTooltip;
        this.Link = link;
        this.Icon = icon;
        this.DesignColor = designColor;
    }
    static Create(name: string, value: string, valueRTL: boolean, link: string, linkTooltip: string, icon: IconDefinition, designColor: string): MyContactMe {
        return new MyContactMe(name, value, valueRTL, link,linkTooltip, icon, designColor);
    }
    static CreateArray(skills: { name: string, value: string, valueRTL: boolean, link: string, linkTooltip: string, icon: IconDefinition, designColor: string }[]): MyContactMe[] {
        return skills.map(skill => new MyContactMe(skill.name, skill.value, skill.valueRTL, skill.link, skill.linkTooltip, skill.icon, skill.designColor));
    }
}

export default class MyInfo {
    static readonly MyDetail: string = `من مهدی جمال هستم، یک برنامه‌نویس فول‌استک که در سال ۱۳۸۶ در ساری متولد شدم. از سال ۱۳۹۹ شروع به فعالیت در حوزه‌ی برنامه‌نویسی و آی‌تی کردم و از سال ۱۴۰۰ به صورت حرفه‌ای در این زمینه مشغول هستم. علاقه‌مندی و تجربه‌ی من در برنامه‌نویسی باعث شده است که این راه را با اشتیاق ادامه دهم.`;

    static readonly MySkills: MySkill[] = [
        MySkill.Create('C#', 90),
        MySkill.Create('MAUI', 75),
        MySkill.Create('MAUI Blazor', 75),
        MySkill.Create('Asp.Net Core', 70),
        MySkill.Create('Windows Forms', 90),
        MySkill.Create('WPF', 90),
        MySkill.Create('UWP', 80),
        MySkill.Create('Html5', 90),
        MySkill.Create('Css3', 80),
        MySkill.Create('JavaScript', 75),
        MySkill.Create('SQL Server', 60),
    ]

    static readonly MyCertificates: MyCertificate[] = [
        MyCertificate.Create('CSharp Windows Application', 'CSharp Windows Application', '/images/certificates/CSharp_Windows-Application.jpg'),
        MyCertificate.Create('ICDL', 'ICDL', '/images/certificates/ICDL.jpg'),
    ]

    static readonly MyContactMe: MyContactMe[] = MyContactMe.CreateArray([
        {
            name: 'تلگرام',
            value: '@MhdiJml',
            valueRTL: false,
            linkTooltip: '@MhdiJml تلگرام',
            link: 'https://telegram.me/MhdiJml',
            icon: faTelegram,
            designColor: "#279eda",
        },
        {
            name: 'ایمیل',
            value: 'mahdiiijamal@gmail.com',
            valueRTL: false,
            linkTooltip: 'mahdiiijamal@gmail.com ایمیل',
            link: 'mailto:mahdiiijamal@gmail.com',
            icon: faEnvelope,
            designColor: "#e1574a",
        },
        {
            name: 'گیتهاب',
            value: '@MahdiJamal',
            valueRTL: false,
            linkTooltip: '@MahdiJamal گیتهاب',
            link: 'https://github.com/MahdiJamal',
            icon: faGithub,
            designColor: "#9969ac",
        },
        {
            name: 'لینکدین',
            value: '@mahdi-jamal',
            valueRTL: false,
            linkTooltip: '@mahdi-jamal لینکدین',
            link: 'https://www.linkedin.com/in/mahdi-jamal',
            icon: faLinkedin,
            designColor: "#0077b0",
        }
    ])
}
